.navsearch[type=search] {
  width: 70vw;
  box-sizing: border-box;
  border: 1px solid #dfdfec;
  border-radius: 10px;
  font-size: 16px;
  color: #fff !important;
  background-color: white;
  background-image: url('../../../../public/images/icons/search.png');
  background-position: 10px 12px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 40px;
  background-size: 18px 18px;

}

.Commenpadding{
  padding-bottom: 100px !important;
}
.marginresponsive{
  margin-left: 10% !important;
}

.font-head {
  font-size: 30px;
}

.navsearchheader1[type=search] {
  width: 33vw;
  box-sizing: border-box;
  /* border: 1px solid black !important; */
  border-radius: 10px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000 !important;
  /* background-color: gray !important; */
  background-image: url('../../../../public/images/icons/search.png');
  background-position: 10px 12px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 40px;
  background-size: 15px 15px;

}

.navsearchheaderlight[type="search"]:focus,
.navsearchheader1[type="search"]:focus {
  outline: none; /* Removes the default outline */
  border: 1px solid #7e66bc; /* Adds a custom border color */
  box-shadow: 0 0 5px #7e66bc; /* Adds a glowing effect */
}

.navsearchheaderlight[type=search] {
  width: 33vw;
  box-sizing: border-box;
  /* border: 1px solid #dfdfec !important; */
  border-radius: 10px;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: black !important;
  background-color: #fff !important;
  background-image: url('../../../../public/images/icons/search.png');
  background-position: 10px 12px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 40px;
  background-size: 15px 15px;
  border: 1px solid #7E66BC !important;


}

.navsearch1[type=search] {
  width: 70vw;
  box-sizing: border-box;
  border: 1px solid #7E66BC;
  border-radius: 10px;
  font-size: 16px;
  background-image: url('../../../../public/images/icons/search.png');
  background-position: 10px 12px;
  background-repeat: no-repeat;
  padding: 8px 20px 8px 40px;
  background-size: 18px 18px;
  color: black !important;
}

.navsearchheaderlight::placeholder {
  color: gray !important;
  /* Replace with your desired color */

}

.navsearchheader1::placeholder {
  color: #000 !important;
  /* Replace with your desired color */
}

.navsearch1::placeholder {
  color: #fff;
  /* Replace with your desired color */
}

.navsearch::placeholder {
  color: #fff !important;
  /* Replace with your desired color */
}

.nav-item {
  margin: 0px 5px;
  font-weight: 600;
}

.navbar {
  z-index: 1000;
  width: 100vw;

}

.nav-title {
  font-weight: 600;
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
}

.nav-item{
  align-content: center;
  width:"fit-content";
}

.app-logo {
  width: 55px;
  /* height: 33px; */
}

.search-container{
  /* width: 40vw; */
  border-radius: 5px 0px 0px 0px;
  outline: none;
}

.color-primary{
  color: #7E66BC !important;
}
.color-primary-light{
  color: #5b298c !important;
}

.search-input{
  font-size: 14px !important;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: left;
}

.nav-tab-text{
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 600;
  text-align: left;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(14px);
}

/* Hover Effect */
.hover-shadow:hover {
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width:695px) {
  .marginresponsive{
    margin-left: 0% !important;
  } 

  .app-logo{
    width: 40px;
    /* height: 33px; */
  }
  .font-head {
    font-size: 24px !important;
  }
  .backcolor{
    background-color: #7E66BC;
    padding: 10px;
    border:1px solid #ced4db;
  }
  .dark-backcolor{
    background-color: #333333;
    padding: 10px;
    border:1px solid gray;
  }

  .navsearchheaderlight[type=search] {
    width: 100% !important;
  }
  .navsearchheader1[type=search] {
    width: 100% !important; 
  }
  .Commenpadding{
    padding-bottom: 75px !important;
  }
  
}


@media (min-width: 2900px) and (max-width: 4000px) {
  .font-head {
    font-size: 70px !important;
  }

}
.mobileview{
  display: none;
}




@media (min-width: 650px) and (max-width: 1000px) {
  .marginresponsive{
    margin-left: 0% !important;
  }
  .backcolor{
    background-color: #7E66BC;
    padding: 10px;
    border:1px solid #ced4db;
  }
  .dark-backcolor{
    background-color: #333333;
    padding: 10px;
    border:1px solid gray;
  }
  .mobileview{
    display:block;
  }
  .searchview{
    display: none;
  }
  .app-logo{
    width: 45px;
    /* height: 33px; */
  }
}




.toggle-wrapper input:checked+.toggle-slider {
  background-color: #34495e;
  /* Dark background color */
}

.toggle-wrapper input+.toggle-slider {
  background-color: #bdc3c7;
  /* Light background color */
}

.toggle-wrapper input:checked+.toggle-slider:before {
  transform: translateX(42px);
  /* Moves the circle to the right */
}

.icon-sun,
.icon-moon {
  transition: opacity 0.4s ease;
}
.notification-badge {
  position: absolute; /* Position the badge relative to the icon */
  top: -5px; /* Adjust the position to be above the icon */
  right: -10px; /* Adjust to align badge with the bell icon */
  background: linear-gradient(135deg, rgb(126, 102, 188), rgb(201, 157, 233));
  color: white; /* White text color */
  border-radius:50px; /* Make the badge circular */
  padding: 3px 9px; /* Padding for the badge */
  font-size: 12px; /* Font size of the badge */
  font-weight: bold; /* Make the text bold */
  display: flex; /* Center the text */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional shadow for depth */
  transition: transform 0.2s; /* Smooth animation on hover */
}

.notification-badge:hover {
  transform: scale(1.1); /* Slightly enlarge the badge on hover */
}
.notification-item:hover {
  background-color: #f0f8ff; /* Change to a lighter color on hover */
}



.selected-menu{
color: #fff !important;
margin-bottom: 0px !important;
border-bottom: 2px solid #fff !important;
}