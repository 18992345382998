body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0 !important;

}

:root {
  --fontpara: 16px;
  --titlefont: 1.750rem;
  --subtitle: 1rem;

}

.font-para {
  font-size: var(--fontpara);
  margin-bottom: 0;
}

.font-title {
  font-size: var(--titlefont);
}

.font-subtitle {
  font-size: var(--subtitle);
}

.pointer {
  cursor: pointer !important;
}


.darkheadcolor {
  color: #7e66bc !important;
}

.lightheadcolor {
  color: #5b298c !important;
}




/* Main container styling */
.fallback-container {
  display: flex;
  flex-direction: column;
  /* Align logo and text vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #fff, #fff);
  /* Gradient background */
  color: #fff;
  /* Text color */
}

/* Animated logo container */
.animated-logo {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* Circular container */
  animation: pulse 2s infinite;
  /* Adds pulsing effect */
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2);
  /* Glowing effect */
}

/* Logo image styling */
.animated-logo img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  border-radius: 50%;
  /* Ensures the logo remains circular */
  animation: spin 2s linear infinite;
  /* Rotating animation */
}

/* Loading text styling */
.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: black;
  animation: fade 2s infinite;
}

/* Spin animation for the logo */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Pulse animation for the container */
@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.4), 0 0 40px rgba(255, 255, 255, 0.2);
  }

  50% {
    transform: scale(1.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.3);
  }
}

/* Fade animation for the loading text */
@keyframes fade {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.home-1 .service__item-inner {
  border: 2px solid #5b298c !important;
}

.scrolls {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.scrolls:hover {
  color: #5b298c;
  /* Hover color */
}

.active-link {
  color: #5b298c !important;
  /* Active link text color */
  border-bottom: 3px solid #5b298c;
  /* Underline active link */
}

/* @keyframes smooth-slide {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }

}


.smooth-slide-animation {
  margin-top: 80px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 400px;
  overflow: hidden;
}

.banner-image {
  background-image: url('../public/Imagesweb/logo.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 60% !important;
  height: 100% !important;
  z-index: 1;
} */





@keyframes rotate-logo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.smooth-slide-animation {
  margin-top: 80px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 400px;
  overflow: hidden;
}
.smooth-slide-animation-value {
  margin-top: 80px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 200px;
  overflow: hidden;
}

.banner-container {
  position: relative;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  background-image: url('../public/Imagesweb/logo-out.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;

  /* Only background logo rotates */
  animation: rotate-logo 15s linear infinite;
}

.banner-image-value {
  background-image: url('../public/Imagesweb/logo-out.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;

  /* Only background logo rotates */
  animation: rotate-logo 15s linear infinite;
}

.centered-image {
  background-image: url('../public/Imagesweb/logo-in.webp'); /* Replace with your center image */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%; /* Adjust size as needed */
  height: 60%; /* Adjust size as needed */
  position: absolute;
  z-index: 2; /* Ensures it appears above the rotating logo */
}



.centered-image-value {
  background-image: url('../public/Imagesweb/logo-in.webp'); /* Replace with your center image */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%; /* Adjust size as needed */
  height: 60%; /* Adjust size as needed */
  position: absolute;
  z-index: 2; /* Ensures it appears above the rotating logo */
}





.service__item-inner {
  will-change: "transform, opacity";
}

.status-Img {
  width: 30px !important;
  height: 30px !important;
}
.titlefont-size{
  font-size: 38px !important;
}


.common-width{
  width: 1200px !important;
}
@media screen and (max-width:695px) {
  
  .common-width{
    width: 95% !important;
  }
  .titlefont-size{
    font-size: 20px !important;
  }
  .centered-image {
    
    width: 70% !important; /* Adjust size as needed */

  }
  
}


@media screen and (max-width:1095px) {
  .centered-image {
  
    width: 70% !important; /* Adjust size as needed */
  }

  .banner-container {
    width: 90% !important;

  }
}


@media (min-width: 1900px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1500px !important;
  }
}


