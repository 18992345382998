.img-layout{
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    height: 85vh;   
}
.img-layout1{
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
}

  
.text-overlay{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:100%;
    padding:10px;
    /* background-image: linear-gradient(0deg, rgb(0 0 0 / 75%) 0%, rgb(0 0 0 / 52%) 36%, rgb(0 0 0 / 55%) 46%); */
    /* background-image:linear-gradient(3deg, rgb(0 0 0 / 75%) 0%, #000000ad 36%, #000000b5 46%); */
   /* background: linear-gradient(90deg, rgb(203 174 233) 0%, rgb(126, 102, 188) 45%, rgb(91 41 140 / 41%) 100%); */

    background-color:#5B298C !important;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  
}

.paratext{
    font-size: 0.875rem;
    margin-bottom: 0px;
}
a{
    text-decoration: none;
}
.btn-card{
    cursor: pointer;
}
.container-value{
    border: 1px solid #ededed;
    height: 85vh;
    margin-top: 3%;
}
.background-img{
    width: 100%;
    height: 100%;
}
.logo-image{
    width: 130px !important;
    height: 130px !important;

 }
 .logo2{
    width:280px !important;
    height: 60px !important ;
 }

@media only screen and (max-width: 768px) {
/*    
    .img-layout{
        height: 50vh !important;
      
    } */
     .social-icons{
        display: flex;
     flex-wrap: wrap;
     }

     .container-value{
         width:95% !important;
         margin-top: 5%;
     }
     .logo-image{
        width: 60px !important;
        height: 60px !important;

     }
     .logo2{
        height:50px !important ;
     }
  }


  .custom-indicator {
    width: 10px;
    height: 10px;
    background-color:lightgray;
    border-radius: 50%;
    margin: 5px;
    display: inline-block;
    cursor: pointer;
  }
  
  .custom-indicator.active {
    background-color: #5b298c; /* Change color when active */
    width: 15px;
    border-radius: 40%;


  }
  
  .slide-container {
    width: 100%; /* Ensures full width */
    /* max-width: 1200px; Adjusts width on large screens */
    margin: auto; /* Centers the slider */
    padding: 0%;
  }
  
  .slide-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0%;
  }
  
  
  .slide-image {
    width: 100%;
    height: 400px; /* Default height for web */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  /* 📱 Mobile View */
  @media (max-width: 768px) {
    .slide-image {
      height: 100px !important; /* Reduce height on mobile */
    }
  }
  
  /* 📱 Tablet View */
  @media (min-width: 769px) and (max-width: 1024px) {
    .slide-image {
      height: 180px !important; /* Adjust height for tablets */
    }
  }
  

  .input-container {
    position: relative;
  }
  .input-icon {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: #5b298c;
  }
  .input-field {
    padding-left: 45px;
    border-radius: 20px;
    height: 40px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  .input-field:focus {
    border-color: #5b298c;
    box-shadow: 0 0 5px rgba(106, 17, 203, 0.5);
  }

  .buttonall{
    background-color: #5b298c !important;
    border:none !important;
    font-size: 16px !important;
    padding: 10px !important;
    border-radius: 20px !important;
  }



  .slide-container1 {
    width: 100%; /* Ensures full width */
    /* max-width: 1200px; Adjusts width on large screens */
    margin: auto; /* Centers the slider */
    padding: 0%;
  }
  
  .slide-item1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0%;
  }
  
  
  .slide-image1 {
    width:100%;
    height: 200px; /* Default height for web */
    max-height: 350px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  /* 📱 Mobile View */
  @media (max-width: 768px) {
    .slide-image1 {
      height: 100px !important; /* Reduce height on mobile */
      background-size: contain !important;
  
    }
  }
  
  /* 📱 Tablet View */
  @media (min-width: 769px) and (max-width: 1024px) {
    .slide-image1 {
      height: 180px !important; /* Adjust height for tablets */
      background-size: contain !important;
  
    }
  }
  
  
  
  @media (min-width: 1400px) and (max-width: 3000px) {
    .slide-image1 {
      height: 200px !important; /* Adjust height for tablets */
    }
  }




.custom-indicator1 {
  width: 5px;
  height: 5px;
  background-color:lightgray;
  border-radius: 50%;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
}

.custom-indicator1.active {
  background-color:#fff; /* Change color when active */
  width: 13px;
  border-radius: 20%;

}

.discription-font{
  font-size: 12px;
  color:rgb(234, 234, 234);
}

.common-color{
  background-color: #5B298C !important;
  height: auto !important;
}

  